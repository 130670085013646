<template>
    <div>
        <div v-show="this.showSidebar" class="sidebar-overlay d-sm-none" @click="handleOverlayClick"></div>
        <div id="sidebar-parent" :class="[
            'sidebar d-sm-flex flex-column ',
            {
                'd-flex sidebar__mobile': this.showSidebar,
                'd-none': !this.showSidebar,
            },
        ]">
            <div class="sidebar-brand cursor-pointer" @click="this.$router.push('/' + this.golfClubData?.slug)">
                <img class="w-100" :src="require('../assets/imgs/logo.png')" />
            </div>

            <div class="sidebar-routes" v-if="!boardErrorState">
                <div class="sidebar-routes-div">
                    <template v-for="sidebarItem of filteredSidebarItems">
                        <router-link v-if="shouldBeVisible(sidebarItem?.text)"
                            :to="`/${$route.params.board}${sidebarItem.to}`" :key="sidebarItem.text"
                            :active-class="$route.path === `/${$route.params.board}${sidebarItem.to}` ? 'activeClass' : ''"
                            :class="['sidebar-item']" @click="handleSidebarLinkClick">
                            <div v-html="sidebarItem.icon" class="sidebar-icon"></div>
                            <div class="sidebar-text">
                                {{ sidebarItem.text }}
                            </div>
                        </router-link>
                    </template>
                    <div class="settings" v-longpress="{ timeout: 5000, function: handleSettingsLongPress }"></div>
                </div>
                <div class="d-none d-md-flex align-items-center flex-column">
                    <div class="bubble">
                        Scan for mobile view
                        <div class="arrow">
                            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink" width="531.74" height="460.5"
                                viewBox="0 0 531.74 460.5" overflow="visible" enable-background="new 0 0 531.74 460.5"
                                xml:space="preserve">
                                <defs>
                                    <linearGradient id="grad1" x1="0%" y1="0%" x2="0%" y2="100%">
                                        <stop offset="0%" style="stop-color:#7ed0a2;stop-opacity:1" />
                                        <stop offset="100%" style="stop-color:#50c299;stop-opacity:1" />
                                    </linearGradient>
                                </defs>
                                <polygon fill="url(#grad1)" points="530.874,0.5 265.87,459.5 0.866,0.5" />
                            </svg>

                        </div>
                    </div>
                    <qrcode-vue class="qr"
                        :value="`https://boards.creativehonourboards.co.uk/${$route.params.board}`"></qrcode-vue>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import router from "@/router";
import SidebarItems from "../constants/SidebarItems";
import mainmixin from "../mixins/index";
import QrcodeVue from 'qrcode.vue'

export default {
    components: { router, QrcodeVue },
    mixins: [mainmixin],
    data() {
        return {
            SidebarItems,
        };
    },
    computed: {
        filteredSidebarItems() {
            return SidebarItems.filter((item) => (item.visible ?? true))
        }
    },
    methods: {
        handleSettingsLongPress() {
            this.$router.push({
                path: `/${this.$route.params.board}/settings`,
            });
        },
        handleSidebarLinkClick() {
            if (window.innerWidth <= 575) {
                this.toggleSidebar();
            }
        },
        handleOverlayClick(event) {
            this.toggleSidebar();
            const target = event.target;
            const sidebar = this.$el.querySelector(".sidebar");
            if (target === sidebar) {
                return;
            }
            this.$emit("overlay-clicked");
        },
        shouldBeVisible(name) {
            name = name?.toLowerCase();
            switch (name) {
                case 'calendar':
                    if (this.golfClubData?.calendar?.length) {
                        return true;
                    }
                    return false;
                case 'gallery':
                    if (this.golfClubData?.galleries?.length) {
                        return true;
                    }
                    return false;
                case 'sponsors':
                    if (this.golfClubData?.sponsors?.length) {
                        return true;
                    }
                    return false;
                case 'news':
                    if (this.golfClubData?.news?.length) {
                        return true;
                    }
                    return false;
                default:
                    return true;
            }
        }
    },
};
</script>

<style lang="scss" scoped>
.qr {
    width: 90px !important;
    height: 90px !important;
}

.settings {
    height: 66px;
}

.bubble {
    max-width: 100%;
    margin: 0 0 20px 0;
    background: linear-gradient(to left, #50c299, #acdeaa);
    padding: 10px 10px;
    text-align: center;
    font-family: arial;
    position: relative;
    border-radius: 5px;
    font-weight: normal;
    line-height: 1.2;
    font-size: 15px;

    .arrow {
        position: absolute;
        bottom: -18px;
        width: 20px;
        height: 20px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        align-items: flex-start;

        svg {
            width: 100%;
            height: 100%;
        }
    }
}
</style>
