<template>
    <div class="gallery position-relative">
        <h2 class="my-4 text-center text-md-start">Gallery</h2>
        <div class="gallery__box bg-white px-4 py-2">
            <div class="row row-cols-lg-5">
                <div class="p-2 col-6 col-md-4 col-xs-6 gallery__lg" v-for="(data, index) in galleryData" :key="index">
                    <ImageWithFallback :src="data.imageFile" @click="galleryCarousel(index)" class="gallery__box-img"/>
                </div>
            </div>
        </div>
        <div class="position-relative h-100" v-if="randerCarousal == true && this.galleryCarousalData.length > 0">
            <b-modal size="lg" v-model="showCarousel" class="gallery__modal" hide-footer
                hide-header="" headerClass="border-0" :centered="true">
                <GalleryCarousel :galleryContent="this.galleryCarousalData" />

            </b-modal>
        </div>
    </div>
</template>

<script>
    import GalleryCarousel from "../components/GalleryCarousel.vue";
    import ImageWithFallback from "@/components/ImageWithFallback.vue";

    export default {
        name: "Gallery",
        components: {
            GalleryCarousel,
            ImageWithFallback,
        },
        data() {
            return {
                galleryData: [],
                galleryCarousalData: [],
                showCarousel: false,
                selectedImageIndex: null,
                randerCarousal: false,
            };
        },
        created() {
            if (
                this.golfClubData &&
                this.golfClubData?.galleries &&
                this.golfClubData?.galleries.length > 0
            ) {
                this.galleryData = this.golfClubData?.galleries;
            }
        },
        watch: {
            golfClubData: {
                handler(newData) {
                    if (
                        newData &&
                        newData.key_persons &&
                        newData.key_persons.length > 0
                    ) {
                        this.galleryData = this.golfClubData?.galleries;
                    }
                },
            },
        },
        watch: {
            golfClubData() {
                this.galleryData = this.golfClubData?.galleries;
            },
            galleryData(newVal) {
                console.log("Events changed", newVal);
            },
        },

        methods: {
            galleryCarousel(index) {
                const galleryDataCopy = [...this.galleryData];

                this.galleryCarousalData = galleryDataCopy;
                if (this.galleryCarousalData.length > 0) {
                    const selectedImage = this.galleryCarousalData.splice(
                        index,
                        1
                    )[0];
                    this.galleryCarousalData.unshift(selectedImage);
                }

                this.showCarousel = true;
                setTimeout(() => {
                    this.randerCarousal = true;
                }, 100);
            },
            toggle() {
                this.showCarousel = !this.showCarousel;
                this.galleryCarousalData = [];
            },
        },
    };
</script>

<style lang="scss" scoped>
.custom-profile {
    &__name {
        font-size: 15px;
        font-weight: 600;
        line-height: 20px;
        color: rgba(255, 255, 255, 1);
        margin-right: 10px;
    }
}

.gallery {
    max-height: calc(100vh - 64px);

    &__lg {
        @media (min-width: 992px) {
            width: 20% !important;
        }
    }

    &__box {
        border-radius: 30px;

        &-img {
            cursor: pointer;
            border-radius: 20px !important;
            object-fit: cover;
            object-position: center;

            @media (max-width: 5000px) {
                aspect-ratio: 1 !important;
                width: 100% !important;
                height: auto !important;
            }

            @media (max-width: 1440px) {
                width: 100% !important;
                height: 200px !important;
            }

            @media (max-width: 1199.99px) {
                height: 160px !important;
            }

            @media (max-width: 1024.99px) {
                height: 125px !important;
            }

            @media (max-width: 768.99px) {
                height: 110px !important;
            }

            @media (max-width: 575.99px) {
                height: 100% !important;
            }

            @media (max-width: 320.99px) {
                height: 100px !important;
            }

            @media (min-width: 992px) {
                height: 200px !important;
            }
        }
    }
}

.custom-btn {
    background-color: #9fa0a0 !important;

    &:hover {
        background-color: #9fa0a0 !important;
    }

    &:active {
        background-color: #9fa0a0 !important;
    }
}
</style>
