<template>
    <div>
        <h2 class="mb-3 text-center text-md-start">Search</h2>
        <BRow class="d-flex justify-content-between search__input">
            <BForm>
                <div class="search__inputDiv d-flex align-items-center">
                    <input id="keyboardTextField" :value="searchInput" @input="onInputChange" type="text"
                        placeholder="Search for a person" class="search__inputDiv-input" />
                    <button class="search__inputDiv-btn">
                        <i class="fa fa-search"></i>
                        Find
                    </button>
                </div>
            </BForm>
        </BRow>

        <KeyBoardUI v-if="keyboardValue && isTouchable" @onChange="onKeyboardChange" @onKeyPress="onKeyPress"
            :input="searchInput" />
        <div @click="isKeyboardOpen = false">
            <BRow class="mt-5" v-if="getFilteredData.length">
                <BCol lg="8">
                    <div v-for="(person, index) in getFilteredData" :key="index">
                        <PersonCard :id="person.id" :name="person?.name" :position="person?.extra"
                            :company="person.companyName" :avatar="person?.image" :companyLogo="company01"
                            :singleUserData="singleUserData" :selected="selected" />
                    </div>
                </BCol>

                <BCol lg="4">
                    <div class="text-muted mb-3">
                        <i class="fa fa-filter"></i>
                        <span class="text-muted mx-2">Filter By</span>
                    </div>
                    <div>
                        <div class="d-flex justify-content-between align-items-center mb-2">
                            <p class="fw-bold mb-0">{{ title }}</p>
                            <i class="fa fa-chevron-right"></i>
                        </div>
                        <BCard class="border border-0 filterCard shadow-lg py-3 px-2">
                            <div class="d-block justify-content-between">
                                <div v-for="(filter, index) in this.golfClubData
                                    .awards" :key="index">
                                    <FilterCard :name="filter.name" @change="checkfunc" :length="filter.persons.length"
                                        :selectedCheckBox="selectedCheckBox" />
                                </div>
                            </div>
                        </BCard>
                    </div>
                    <div>
                        <div class="d-flex justify-content-between align-items-center mb-2">
                            <p class="fw-bold mb-0">{{ titleDate }}</p>
                            <i class="fa fa-chevron-right"></i>
                        </div>
                        <BCard class="border border-0 filterCard shadow-lg py-3 px-2">
                            <div class="d-block justify-content-between">
                                <div v-for="(
                                        filter, index
                                    ) in calculateDateRanges" :key="index">
                                    <FilterCard :name="filter.start" :end="filter.end"
                                        :selectedCheckBox="selectedCheckDateBox" :length="filter.person"
                                        @change="checkDatefunc" />
                                </div>
                            </div>
                        </BCard>
                    </div>
                </BCol>
            </BRow>
            <BRow class="mt-5" v-if="searchInput.length == 0">
                <BCol class="mx-0 px-2" lg="4" v-if="ComunityDetails !== null">
                    <CommunityCard :data="ComunityDetails" />
                </BCol>
                <BCol class="mx-0 px-2" lg="4">
                    <RecentWinners />
                </BCol>
                <BCol class="mx-0 px-2" lg="4">
                    <SearchSponsorCard />
                </BCol>
            </BRow>
        </div>

        <b-modal v-model="openModal" size="lg" hide-header hide-footer class="winnerAward-modal" noFade :centered="true"
            v-cloak>
            <div class="position-relative" @click="toggle">
                <div class="winnerAward-modal__close">
                    <img :src="closeImg" />
                </div>
            </div>

            <BRow>
                <BCol sm="12">
                    <AwardedPersonInformation :personData="this.selectedPersonData" :toggle="toggle" />
                </BCol>
            </BRow>
        </b-modal>
    </div>
</template>

<script>
    import { debounce } from 'lodash';
    import PersonCard from "@/components/PersonCard.vue";
    import FilterCard from "@/components/FilterCard.vue";
    import CommunityCard from "@/components/CommunityCard.vue";
    import SearchSponsorCard from "@/components/SearchSponsorCard.vue";
    import RecentWinners from "@/components/RecentWinners.vue";

    import close from "../assets/svgs/close.svg";
    import KeyBoardUI from "../components/KeyBoardUI.vue";

    import company01 from "@/assets/svgs/ecliansen.svg";
    import { ref } from "vue";
    import AwardedPersonInformation from "@/components/AwardedPersonInformation.vue";

    export default {
        components: {
            PersonCard,
            FilterCard,
            CommunityCard,
            RecentWinners,
            KeyBoardUI,
            SearchSponsorCard,
            AwardedPersonInformation,
        },

        data() {
            return {
                selected: "",
                closeImg: close,
                selected: false,
                ComunityDetails: null,
                personDetail: this.golfClubData?.key_persons,
                openModal: false,
                selectedPersonData: {},
                searchInput: "",
                isKeyboardOpen: false,
                title: "Prefered Job Type",
                titleDate: "Date posted",
                company01: company01,
                value: false,
                dataRange: [],
                selectedCheckBox: [],
                selectedCheckDateBox: [],
                isTouchable: false,
                keyboardValue: false,
            };
        },
        mounted() {
            let tempthis = this;

            window.addEventListener("click", function (e) {
                if (document.getElementById("keyboardId")?.contains(e.target)) {
                    tempthis.keyBoardToggle(true);
                } else if (
                    document.getElementById("keyboardTextField")?.contains(e.target)
                ) {
                    tempthis.keyBoardToggle(true);
                } else {
                    tempthis.keyBoardToggle(false);
                }
            });

            if (this.golfClubData) {
                this.ComunityDetails = {
                    imageFile: this.golfClubData?.backgroundImage,
                    name: this.golfClubData?.name,
                    extra: this.golfClubData?.info,
                };
            }
            this.isTouchable =
                "ontouchstart" in window || navigator.maxTouchPoints > 0;
        },
        methods: {
            keyBoardToggle(value) {
                this.keyboardValue = value;
            },

            onKeyboardChange: debounce(function (input) {
              this.searchInput = input;
            }, 600),

            onInputChange: debounce(function (input) {
               this.searchInput = input.target.value;
            }, 600),

            onKeyPress(button) {
                if (button === "{enter}") {
                    this.isKeyboardOpen = false;
                }
                if (button === "{bksp}") {
                    this.searchterm = "";
                }
            },
            
            singleUserData(personData) {
                this.selectedPersonData = { ...personData };
                this.selected = personData.id;
                this.getPersonAwards({
                    personName: personData.name,
                    onSuccess: () => {
                        this.openModal = !this.openModal;
                    },
                });
            },

            toggle() {
                this.openModal = !this.openModal;
                this.selected = "";
            },

            checkfunc(checkboxValue) {
                let index = this.golfClubData.awards.findIndex(
                    (obj) => obj.name == checkboxValue
                );
                if (index != -1) {
                    if (
                        this.selectedCheckBox.some(
                            (obj) => obj.start == checkboxValue
                        )
                    ) {
                        this.selectedCheckBox = this.selectedCheckBox.filter(
                            (value) => value.start !== checkboxValue
                        );
                    } else {
                        this.selectedCheckBox.push({ start: checkboxValue });
                    }
                }
            },

            checkDatefunc(checkboxValue) {
                let filterobj = this.dataRange.find(
                    (obj) => obj.start == checkboxValue
                );
                if (filterobj) {
                    if (
                        this.selectedCheckDateBox.some(
                            (obj) => obj.start == filterobj.start
                        )
                    ) {
                        this.selectedCheckDateBox =
                            this.selectedCheckDateBox.filter(
                                (value) => value.start !== filterobj.start
                            );
                    } else {
                        this.selectedCheckDateBox.push({
                            start: filterobj.start,
                            end: filterobj.end,
                        });
                    }
                }
            },

            

            applyFilters(person) {
                if (this.selectedCheckDateBox.length === 0 && this.selectedCheckBox.length === 0) {
                    return true;
                }

                const personAward = this.golfClubData.awards.find(award => award.id === person.award);

                const dateFilterValid = this.selectedCheckDateBox.length === 0 ||
                    this.selectedCheckDateBox.some(filter =>
                        filter.start <= person.date && filter.end >= person.date
                    );
                const checkboxFilterValid = this.selectedCheckBox.length === 0 ||
                    (personAward && this.selectedCheckBox.some(filter =>
                        filter.start === personAward.name
                    ));

                return dateFilterValid && checkboxFilterValid;
            }
        },
        computed: {
            calculateDateRanges() {
                const personsByYearRange = [];
                this.golfClubData?.awards?.forEach(award =>
                    award.persons.forEach(person => {
                        const yearGroup = Math.floor(parseInt(person.date) / 5) * 5;
                        const range = personsByYearRange.find(r => r.start === yearGroup);
                        range ? range.person++ : personsByYearRange.push({ start: yearGroup, end: yearGroup + 4, person: 1 });
                    })
                );
                personsByYearRange.sort((a, b) => a.start - b.start);
                this.dataRange = personsByYearRange;
                return personsByYearRange;
            },

            getFilteredData() {
                if (!this.golfClubData || !this.searchInput) return [];

                return this.golfClubData.awards.flatMap(award =>
                    award.persons.filter(person => {
                        const matchesSearch = person.name.toLowerCase().includes(this.searchInput.toLowerCase());
                        const passesFilters = this.applyFilters(person);
                        return matchesSearch && passesFilters;
                    })
                );
            },

        },
        watch: {
            golfClubData() {
                this.ComunityDetails = {
                    imageFile: this.golfClubData.backgroundImage,
                    name: this.golfClubData?.name,
                    extra: this.golfClubData?.info,
                };
            },

            ComunityDetails(newVal) { },
        },
    };
</script>

<style lang="scss"></style>
