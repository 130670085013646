<template>
    <BRow class="d-none justify-content-between align-items-center d-lg-flex mt-2">
        <BCol v-show="!isRoute">
            <BForm class="position-relative d-flex align-items-center">
                <BFormInput id="keyboardTextField" :value="searchterm" @input="updateSearchTerm" class="border border-0 header__searchInput ps-sm-5 px-lg-5"
                    placeholder="Search for a person" />
                <div>
                    <i class="fa fa-search header__searchIcon position-absolute"></i>
                </div>
            </BForm>
        </BCol>

        <BCol class="d-flex align-items-center justify-content-end">

            <img class="header__top-row__img d-lg-none" :src="require('../assets/imgs/searchIcon.png')" />

        </BCol>
    </BRow>
    <BRow class="d-none justify-content-between align-items-center d-sm-flex d-lg-none mt-2">
        <BCol>
            <BForm class="position-relative" v-show="isInputVisible">
                <BFormInput id="keyboardTextField" class="border border-0 header__searchInput ps-sm-5 px-lg-5" placeholder="Search for a person" ref="searchInput"
                    @keyup.enter="search" @blur="hideInput" />
                <i class="fa fa-search header__searchIcon position-absolute"></i>
            </BForm>
        </BCol>
        <BCol class="d-flex align-items-center justify-content-end" v-if="!isInputVisible">
            <i class="d-lg-none fa fa-search fa-2xl mx-3" @click="showInput"></i>
        </BCol>
    </BRow>
    <BRow class="d-sm-none d-flex header__top-row">
        <div v-if="!isInputVisible" class="d-flex justify-content-between align-items-center">
            <img class="header__top-row__img" :src="require('../assets/imgs/sidebarIcon.png')" @click="handleHeaderClick" />
            <img class="header__top-row__brand cursor-pointer" :src="require('../assets/imgs/logo.png')" @click="this.$router.push('/' + this.golfClubData?.slug)" />
            <img class="header__top-row__img" :src="require('../assets/imgs/searchIcon.png')" @click="showInput" />
        </div>
        <BForm class="position-relative" v-show="isInputVisible">
            <BFormInput id="keyboardTextField" class="border border-0 ps-5" placeholder="Search for a person" ref="searchInput" @keyup.enter="search"
                :value="searchterm" @input="updateSearchTerm" @blur="hideInput" />
            <i class="fa fa-search header__searchIcon position-absolute"></i>
        </BForm>
    </BRow>

    <div>
        <BRow class="mt-5" v-if="searchterm?.length > 0">
            <BCol lg="8">
                <div v-for="(person, index) in getFilteredData()" :key="index">
                    <PersonCard :id="person.id" :name="person?.name" :position="person?.extra" :company="person.companyName" :avatar="person?.image"
                        :companyLogo="company01" :singleUserData="singleUserData" :selected="selected" />
                </div>
            </BCol>

            <BCol lg="4">
                <div class="text-muted mb-3">
                    <i class="fa fa-filter"></i>
                    <span class="text-muted mx-2">Filter By</span>
                </div>
                <div>
                    <div class="d-flex justify-content-between align-items-center mb-2">
                        <p class="fw-bold mb-0">{{ title }}</p>
                        <i class="fa fa-chevron-right"></i>
                    </div>
                    <BCard class="border border-0 filterCard shadow-lg py-3 px-2">
                        <div class="d-block justify-content-between">
                            <div v-for="(filter, index) in this.golfClubData
                                .awards" :key="index">
                                <FilterCard :name="filter.name" @change="checkfunc" :length="filter.persons.length" :selectedCheckBox="selectedCheckBox" />
                            </div>
                        </div>
                    </BCard>
                </div>
                <div>
                    <div class="d-flex justify-content-between align-items-center mb-2">
                        <p class="fw-bold mb-0">{{ titleDate }}</p>
                        <i class="fa fa-chevron-right"></i>
                    </div>
                    <BCard class="border border-0 filterCard shadow-lg py-3 px-2">
                        <div class="d-block justify-content-between">
                            <div v-for="(filter, index) in calculateDateRanges" :key="index">
                                <FilterCard :name="filter.start" :end="filter.end" :selectedCheckBox="selectedCheckDateBox" :length="filter.person"
                                    @change="checkDatefunc" />
                            </div>
                        </div>
                    </BCard>
                </div>
            </BCol>
        </BRow>

        <b-modal v-model="openModal" size="lg" hide-header hide-footer class="winnerAward-modal" noFade :centered="true" v-cloak>
            <div class="position-relative" @click="toggle">
                <div class="winnerAward-modal__close">
                    <img :src="closeImg" />
                </div>
            </div>

            <BRow>
                <BCol sm="12">
                    <AwardedPersonInformation :personData="this.selectedPersonData" />
                </BCol>
            </BRow>
        </b-modal>
    </div>
</template>

<script>
    import { debounce } from 'lodash';
    import mainmixin from "../mixins/index";
    import PersonCard from "@/components/PersonCard.vue";
    import FilterCard from "@/components/FilterCard.vue";
    import CommunityCard from "@/components/CommunityCard.vue";
    import SearchSponsorCard from "@/components/SearchSponsorCard.vue";
    import RecentWinners from "@/components/RecentWinners.vue";
    import close from "../assets/svgs/close.svg";
    import company01 from "@/assets/svgs/ecliansen.svg";
    import AwardedPersonInformation from "@/components/AwardedPersonInformation.vue";
    import KeyBoardUI from "../components/KeyBoardUI.vue";
    export default {
        components: {
            PersonCard,
            FilterCard,
            CommunityCard,
            KeyBoardUI,
            RecentWinners,
            SearchSponsorCard,
            AwardedPersonInformation,
        },
        props: {
            searchterm: {
                type: String,
                default: "",
            },
        },

        data() {
            return {
                sidebar: false,

                isInputVisible: false,
                selected: "",
                closeImg: close,
                selected: false,
                ComunityDetails: null,
                personDetail: this.golfClubData?.key_persons,
                openModal: false,
                selectedPersonData: {},
                title: "Prefered Job Type",
                titleDate: "Date posted",
                company01: company01,
                value: false,
                dataRange: [],
                selectedCheckBox: [],
                selectedCheckDateBox: [],
                routeChange: "",
            };
        },
        mixins: [mainmixin],

        methods: {
            updateSearchTerm: debounce(function(value) {
                this.$emit("update:searchterm", value);
            }, 300),
            
            handleHeaderClick() {
                this.toggleSidebar();
            },

            showInput() {
                this.isInputVisible = true;
                this.$nextTick(() => {
                    this.$refs.searchInput.focus();
                });
            },
            search() {
                this.isInputVisible = false;
            },
            hideInput() {
                this.isInputVisible = false;
            },
            singleUserData(personData) {
                this.selectedPersonData = { ...personData };
                this.selected = personData.id;
                console.log("this.selectedPersonData", this.selectedPersonData);
                this.getPersonAwards({
                    personName: personData.name,
                    onSuccess: () => {
                        this.openModal = !this.openModal;
                    },
                });
            },
            toggle() {
                this.openModal = !this.openModal;
                this.selected = "";
            },

            checkfunc(checkboxValue) {
                console.log("checkboxValue", checkboxValue);
                let index = this.golfClubData.awards.findIndex(
                    (obj) => obj.name == checkboxValue
                );
                if (index != -1) {
                    if (
                        this.selectedCheckBox.some(
                            (obj) => obj.start == checkboxValue
                        )
                    ) {
                        this.selectedCheckBox = this.selectedCheckBox.filter(
                            (value) => value.start !== checkboxValue
                        );
                    } else {
                        this.selectedCheckBox.push({ start: checkboxValue });
                    }
                }
            },
            checkDatefunc(checkboxValue) {
                let filterobj = this.dataRange.find(
                    (obj) => obj.start == checkboxValue
                );
                if (filterobj) {
                    if (
                        this.selectedCheckDateBox.some(
                            (obj) => obj.start == filterobj.start
                        )
                    ) {
                        this.selectedCheckDateBox =
                            this.selectedCheckDateBox.filter(
                                (value) => value.start !== filterobj.start
                            );
                    } else {
                        this.selectedCheckDateBox.push({
                            start: filterobj.start,
                            end: filterobj.end,
                        });
                        console.log("set", this.selectedCheckDateBox);
                    }
                }
            },
            getFilteredData() {
                console.log(
                    "this.selectedCheckBox.length > 0",
                    this.selectedCheckDateBox
                );
                if (this.golfClubData?.awards?.length > 0) {
                    if (this.searchterm == "") {
                        console.log("agia  andar", this.searchterm);
                        this.value = false;
                        const allPersons = this.golfClubData?.awards?.flatMap(
                            (award) => award.persons
                        );
                        return allPersons;
                    } else {
                        if (
                            this.selectedCheckBox.length == 0 &&
                            this.selectedCheckDateBox.length == 0
                        ) {
                            let filteredPersons = [];
                            this.golfClubData?.awards?.forEach((award) => {
                                const persons = award?.persons?.filter((person) =>
                                    person?.name
                                        .toLowerCase()
                                        .includes(this.searchterm.toLowerCase())
                                );

                                filteredPersons = filteredPersons.concat(persons);
                            });

                            return filteredPersons;
                        } else if (
                            this.selectedCheckBox.length > 0 &&
                            this.selectedCheckDateBox.length == 0
                        ) {
                            console.log("hello  award");
                            let filteredPersons = [];

                            this.golfClubData?.awards?.forEach((award) => {
                                if (
                                    this.selectedCheckBox.some(
                                        (obj) => obj.start == award.name
                                    )
                                ) {
                                    const persons = award?.persons?.filter(
                                        (person) =>
                                            person?.name
                                                .toLowerCase()
                                                .includes(
                                                    this.searchterm.toLowerCase()
                                                )
                                    );
                                    filteredPersons =
                                        filteredPersons.concat(persons);
                                }
                            });

                            return filteredPersons;
                        } else if (this.selectedCheckDateBox.length > 0) {
                            let filteredPersons = [];
                            console.log("hello  date");

                            this.golfClubData?.awards?.forEach((award) => {
                                if (
                                    this.selectedCheckBox.some(
                                        (obj) => obj.start == award.name
                                    )
                                ) {
                                    console.log("when two");
                                    const persons = award?.persons?.filter(
                                        (person) =>
                                            person?.name
                                                .toLowerCase()
                                                .includes(
                                                    this.searchterm.toLowerCase()
                                                ) &&
                                            this.selectedCheckDateBox.some(
                                                (obj) => {
                                                    return (
                                                        obj.start <= person.date &&
                                                        obj.end >= person.date
                                                    );
                                                }
                                            )
                                    );
                                    filteredPersons =
                                        filteredPersons.concat(persons);
                                } else if (
                                    this.selectedCheckDateBox.length > 0 &&
                                    this.selectedCheckBox.length == 0
                                ) {
                                    console.log("else 1");
                                    const persons = award?.persons?.filter(
                                        (person) =>
                                            person?.name
                                                .toLowerCase()
                                                .includes(
                                                    this.searchterm.toLowerCase()
                                                ) &&
                                            this.selectedCheckDateBox.some(
                                                (obj) => {
                                                    return (
                                                        obj.start <= person.date &&
                                                        obj.end >= person.date
                                                    );
                                                }
                                            )
                                    );
                                    filteredPersons =
                                        filteredPersons.concat(persons);
                                }
                            });

                            return filteredPersons;
                        }
                    }
                } else {
                    return [];
                }
            },
        },

        computed: {
            isRoute() {
                return this.$route.path === "/search" || this.$route.path === "/";
            },
            calculateDateRanges() {
                const personsByYearRange = [];

                for (const award of this.golfClubData?.awards) {
                    for (const person of award.persons) {
                        const personYear = parseInt(person.date);
                        const yearGroup = Math.floor(personYear / 5) * 5;
                        const yearRange = yearGroup + " - " + (yearGroup + 4);

                        const existingRange = personsByYearRange.find(
                            (range) =>
                                range.start === yearGroup &&
                                range.end === yearGroup + 4
                        );

                        if (existingRange) {
                            existingRange.person++;
                        } else {
                            personsByYearRange.push({
                                start: yearGroup,
                                end: yearGroup + 4,
                                person: 1,
                            });
                        }
                    }
                }
                personsByYearRange.sort((a, b) => a.start - b.start);
                this.dataRange = personsByYearRange;
                console.log("this.dataRange", this.dataRange);
                return personsByYearRange;
            },
        },
        created() {
            this.routeChange = this.$route.path;
        },
        watch: {
            golfClubData() {
                this.ComunityDetails = {
                    imageFile: this.golfClubData.backgroundImage,
                    name: "Introduction To Community",
                    extra: this.golfClubData.info,
                };
            },

            ComunityDetails(newVal) { },
        },
        mounted() {
            if (this.golfClubData) {
                this.ComunityDetails = {
                    imageFile: this.golfClubData?.backgroundImage,
                    name: "Introduction To Community",
                    extra: this.golfClubData?.info,
                };
            }
        },
    };
</script>

<style></style>
